html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #022c43;
  overflow: hidden;
  display: block;
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
    
  }
}