.auth{
  width: 90%;
  max-width: 400px;
  border: 1px solid #dcdcdc;
  padding: 20px;
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  color: #ffd700;
      font-size: 18px;
}

.auth form{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto 10px auto;
  
}

.auth__success{
  padding: 10px 0;
  background-color: hsl(147, 79%, 87%);
  margin-bottom: 20px;
}

.auth__error{
  padding: 10px 0;
  background-color: hsl(0, 79%, 87%);
  margin-bottom: 20px;
}

.auth form > input, button{
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
}